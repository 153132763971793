<template>
  <PageContainer>
    <div class="layout">
      <div style="width: 100%;display: flex;align-items: center;">
        <el-form
          :inline="true"
          :model="searchModel"
          style="margin-top: 20px;padding-left: 20px"
          size="mini"
        >
          <el-form-item
            label="工程名称:"
            prop="compName"
          >
            <el-input
              v-model="searchModel.compName"
              placeholder="请输入工程/施工单位/建设单位名称"
              clearable
              style="width: 280px"
            >
            <!--<el-button slot="append" @click="busDialog = true">选择</el-button>-->
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              :loading="loading"
              @click="submitSearch"
            >
              {{ searchText }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              v-if="loading"
              type="primary"
              icon="el-icon-close"
              @click="loading = false"
            >
              停止
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <resize-wrap class="flex-1">
        <BaseTable
          id="mixTab"
          ref="xTable1"
          size="mini"
          border
          auto-resize
          resizable
          highlight-current-row
          highlight-hover-row
          export-config
          :max-height="tableHeight"
          :align="allAlign"
          :data="tableData"
          :seq-config="{startIndex: (tablePage.pageNum-1) * tablePage.pageSize}"
          :expand-config="{visibleMethod: expandVisibleMethod,iconOpen: 'el-icon-remove-outline', iconClose: 'el-icon-circle-plus-outline'}"
          @toggle-expand-change="expandToggle"
        >
          <vxe-table-column
            type="expand"
            width="60"
          >
            <template #default />
            <template #content="{ row }">
              <div class="expand-wrapper">
                <vxe-table
                  ref="mateTable"
                  :data="row.newMateList"
                  class="qtable-style"
                  border="none"
                  align="left"
                  :header-cell-style="{'background-color': '#F0F9EB','height':'30px','padding':'0'}"
                  :cell-style="{'background-color': '#F0F9EB','height':'30px','padding':'0'}"
                  :row-class-name="querowClassName"
                >
                  <template v-for="(value, key) of row.newMateList">
                    <vxe-table-column
                      :key="key"
                      :field="key"
                      :title="key"
                      show-overflow="title"
                      min-width="60"
                    />
                  </template>
                <!--<vxe-table-column field="productQueueName" title="生产线"show-overflow="title" min-width="60"/>
                                    <vxe-table-column field="dayWeight" title="当日产量（方）" show-overflow="title" min-width="60" />
                                    <vxe-table-column field="monthWeight" title="当月产量（方）" show-overflow="title" min-width="60" />
                                    <vxe-table-column field="createDateTime" title="最后上报时间" show-overflow="title" min-width="60"/>
                                    <vxe-table-column field="gkDeviceStatus" title="状态" show-overflow="title" min-width="100" :formatter="formatterOnline"></vxe-table-column>-->
                </vxe-table>
              </div>
            </template>
          </vxe-table-column>
          <vxe-table-column
            field="compName"
            title="企业名称"
            show-overflow="title"
            min-width="135"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="ownerCode"
            title="楼号"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="matcCode"
            title="配合比号"
            show-overflow="title"
            min-width="60"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="gradName"
            title="强度等级"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="desiSlum"
            title="设计塌落度(毫米)"
            show-overflow="title"
            min-width="120"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="consSlum"
            title="施工塌落度(毫米)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
          <vxe-table-column
            field="contCapa"
            title="容积(立方米)"
            min-width="60"
            show-overflow="title"
            show-header-overflow="title"
          />
        </BaseTable>
      </resize-wrap>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
    </div>
  </PageContainer>
</template>

<script>
import { getMixList } from '@/apis/quality/concreteQuality'
export default {
  name: 'MixManage',
  data () {
    return {
      searchModel: {
        compName: ''
      },
      loading: false,
      searchText: '查询',
      tableData: [],
      tableHeight: 400,
      allAlign: 'center',
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      }
    }
  },
  component: {

  },
  mounted () {
    this.refreshData()
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      this.loading = true
      this.tableData = []
      // this.currentBusIndex = 0;
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    expandVisibleMethod ({ row }) {
      console.log(88, row)
      if (row.dataMatcMateList.length < 1) {
        return false
      }
      return true
    },
    refreshData () {
      getMixList({
        ...this.searchModel,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          data.rows.forEach(row => {
            const newRow = {}
            // 展开行，表格数据单条
            row.dataMatcMateList.forEach(item => {
              newRow[item.mateName] = item.dosage
            })
            row.newMateList = newRow
          })
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    expandToggle () {

    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  overflow: hidden;
  background-color: white;
}
</style>
